import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IMGUPLOAD from "../../../../src/institute-dashboard-assets/images/Content_upload.svg"
import IMGVALIDATION from "../../../../src/institute-dashboard-assets/images/Content_Validation.svg"
import IMGTESTS from "../../../../src/institute-dashboard-assets/images/Test_Creation.svg"
import IMGWORKSHEET from "../../../../src/institute-dashboard-assets/images/Worksheet_Creation.svg"
import IMGKARYAKATA from "../../../../src/institute-dashboard-assets/images/Karyakarta_Analysis.svg"
import { GET_CONTENT_DATA } from "../../../app/service/content-module/dashboard-service";
import { toast } from "react-toastify";


export const ContentDashboard = () => {
    const [contentInfo, setContentInfo] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        setLoading(true);
        try {
            const payLoad = {
                dashboard_stats: true
            }
            const res: any = await GET_CONTENT_DATA(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    setContentInfo(res);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    }

    const upLoads = () => {
        history.push(`/dashboard/content/upload/boards-list`);
    }
    const valiDations = () => {
        history.push(`/dashboard/content/question-validation`);
    }
    const testCreation = () => {
        history.push(`/dashboard/content/pariksha/test-creation`);
    }
    const worksheetCreation = () => {
        history.push(`/dashboard/content/abhyaas/worksheet`);
    }
    const karyaKarthaAnalysis = () => {
        history.push(`/dashboard/content/karyakarta/list`);
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="border-top border-primary py-3">
                    <h4 className="mt-2  border-bottom pb-2">Content Dashboard</h4>
                    <div className="content_cards_list mt-3">
                        <div className=" ps-3">
                            <div>
                                <div className="content_card cursor_pointer  " onClick={upLoads}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGUPLOAD} />
                                    </div>
                                    <h5 className="f16 mb-0">Content<br /> Upload</h5>
                                </div>
                            </div>
                            <div className=" pt-5 mt-5 text-center">
                                <h2 className="fw-bold up_value">{contentInfo?.content_upload ? contentInfo?.content_upload : 0}</h2>
                                <h5 className="f16 mb-0 text-center">Total Content Upload</h5>
                            </div>
                        </div>
                        <div className="cn_vl"></div>
                        <div>
                            <div className="content_card cursor_pointer cn_validation_bg" onClick={valiDations}>
                                <div className="pe-3 me-3 border_right_1 ">
                                    <img className="content_img mt-1 " src={IMGVALIDATION} />
                                </div>
                                <h5 className="f16 mb-0">Content<br />  Validations</h5>
                            </div>
                            <div className="mt-5 pt-5">
                                <h5 className="f16 mb-0 text-center mt-5">Content Validation</h5>
                            </div>
                        </div>
                        <div className="cn_vl"></div>
                        <div>
                            <div>
                                <div className="content_card cursor_pointer cn_test_bg" onClick={testCreation}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGTESTS} />
                                    </div>
                                    <h5 className="f16 mb-0">Test <br /> Creation</h5>
                                </div>
                            </div>
                            <div className=" pt-5 mt-5 text-center">
                                <h2 className="fw-bold test_value">{contentInfo?.tests_created ? contentInfo?.tests_created : 0}</h2>
                                <h5 className="f16 mb-0 text-center">Total Test Created</h5>
                            </div>
                        </div>
                        <div className="cn_vl"></div>
                        <div>
                            <div>
                                <div className="content_card cursor_pointer cn_worksheet_bg" onClick={worksheetCreation}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGWORKSHEET} />
                                    </div>
                                    <h5 className="f16 mb-0">Worksheet<br />  Creation</h5>
                                </div>
                            </div>
                            <div className=" pt-5 mt-5 text-center">
                                <h2 className="fw-bold worksheet_value">{contentInfo?.worksheets_created ? contentInfo?.worksheets_created : 0}</h2>
                                <h5 className="f16 mb-0 text-center">Total Worksheet Created</h5>
                            </div>
                        </div>
                        <div className="cn_vl"></div>
                        <div>
                            <div>
                                <div className="content_card cursor_pointer cn_karakartas_bg" onClick={karyaKarthaAnalysis}>
                                    <div className="pe-3 me-3 border_right_1 ">
                                        <img className="content_img mt-1 " src={IMGKARYAKATA} />
                                    </div>
                                    <h5 className="f16 mb-0">Karyakartas<br />  Analysis</h5>
                                </div>
                            </div>
                            <div className="mt-5 pt-5">
                                <h5 className="f16 mb-0 text-center mt-5">Total Karyakartas</h5>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}