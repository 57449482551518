import React from 'react'
import { Route, Switch } from 'react-router'
import { ContentWorksheetList } from '../../pages/dashboard/content/abhyaas/worksheet-list';



const NestedContentWorksheetList = () => {
    return (
        <Switch>
            <Route path="/dashboard/content/abhyaas/worksheet" component={ContentWorksheetList}></Route>
        </Switch>
    )
}

export default NestedContentWorksheetList;