import { SchoolListItem } from "../model/school/school-list";
import { UsersListItem } from "../model/users/users-list";
import API from "../utility/axios";
const apiUrl = "/api/v1";

export const GET_DASHBOARD = (): Promise<any> => {
  return API.get(`${apiUrl}/dashboard`);
};

export const GET_SCHOOL_LISTS = (data: any): Promise<SchoolListItem[]> => {
  return API.get(`${apiUrl}/school?limit=${100000}`, { params: data });
};
export const GET_INSTITUTE_LISTS = (data: any): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/organization?limit=${100000}`, { params: data });
};
