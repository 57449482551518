import { UsersListItem } from "../model/users/users-list";
import API from "../utility/axios";
const apiUrl = "/api/v1";

export const CREATE_INSTITUTE = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/organization`, data);
};

export const UPDATE_INSTITUTE = (data: any): Promise<any> => {
  return API.put(`${apiUrl}/organization`, data);
};

export const GET_INSTITUTE = (): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/organization?limit=${100000}`);
};

export const GET_INSTITUTE_Filter = (data: any): Promise<UsersListItem[]> => {
  // return API.get(`${apiUrl}/organization`, { params: data });
  return API.get(`${apiUrl}/organization?limit=${10}&page_num=${0}`, {
    params: data,
  });
};

export const GET_INSTITUTE_PAGE = (
  pageNumber: number,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/organization?limit=${10}&page_num=${pageNumber}`, {
    params: data,
  });
};

export const DELETE_INSTITUTE = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/organization/${id}`);
};

export const GET_INSTITUTE_BY_ID = (id: number): Promise<any> => {
  return API.get(`${apiUrl}/organization?id=${id}`);
};
export const GET_INSTITUTE_BY_CODE = (code: string): Promise<any> => {
  return API.get(`${apiUrl}/organization?organization_code=${code}`);
};

export const GET_INSTITUTE_BY_INSTITUTE_ID = (id: string): Promise<any> => {
  return API.get(`${apiUrl}/organization?organization_code=${id}`);
};
