import API from "../../utility/axios";
const oldApiUrl = "/api/v1";
const apiUrl = "/api/v2";

export const CREATE_CONTENT_QUESTION = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/questions`, data);
};

export const UPDATE_CONTENT_QUESTION = (uuid: any, data: any): Promise<any> => {
  return API.put(`${apiUrl}/questions/${uuid}`, data);
};

export const GET_CONTENT_QUESTIONS = (
  data: any,
  pageNumber?: number,
  limit?: number
): Promise<any> => {
  return API.get(
    `${apiUrl}/questions?page_num=${pageNumber}&limit=${limit ? limit : 10}`,
    {
      params: data,
    }
  );
};
export const GET_CONTENT_RANDOM_QUESTIONS = (
  data: any,
  pageNumber?: number,
  limit?: number
): Promise<any> => {
  return API.get(
    `${apiUrl}/random/questions?page_num=${pageNumber}&limit=${
      limit ? limit : 10
    }`,
    {
      params: data,
    }
  );
};

export const DELETE_CONTENT_QUESTION = (uuid: number): Promise<any> => {
  return API.delete(`${apiUrl}/questions/${uuid}`);
};

export const GET_CONTENT_QUESTION_LIST = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/questions`, { params: data });
};
export const GET_CONTENT_QUESTION_FILTER_LIST = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/questions`, { params: data });
};
export const BULK_UPLOAD_QUESTIONS = (data: any): Promise<any[]> => {
  return API.post(`${apiUrl}/bulk/upload/questions`, data);
};
export const BULK_UPLOAD_QUESTIONS_FILE = (data: any): Promise<any[]> => {
  return API.post(`${oldApiUrl}/file`, data);
};
