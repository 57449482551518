import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { Breadcum } from '../../../components/breadcum';
import NestedUploads from '../../../routes/content-uploads/nested-uploads';


export const ContentUploadInFo = () => {
    const [loading, setLoading] = useState(false);
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Content',
            value: 'content',
            routerLink: '/dashboard/content/view',
            isActive: false
        },
        {
            label: 'Content Upload',
            value: 'Content Upload',
            routerLink: '',
            isActive: true
        }
    ]);

    return (
        <div className="row border-top border-primary py-3">
            <Breadcum breadcumList={breadcumInfo} />
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="mt-2">
                    <ul className="nav nav-tabs custom-tabs scrolling_nav">
                        <li className="nav-item">
                            <NavLink className={splitLocation[4] === "board" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/content/upload/boards-list`, state: { data: "" } }}>Boards</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={splitLocation[5] === "chairmanform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/content/upload/add-question`, state: { data: "" } }}>Add Questions</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={splitLocation[5] === "chairmanform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/content/upload/add-videos`, state: { data: "" } }}>Add Videos</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={splitLocation[5] === "chairmanform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/content/upload/question-status`, state: { data: "" } }}>Validation Status</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={splitLocation[5] === "chairmanform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/content/upload/question-code`, state: { data: "" } }}>Search by Question Code</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={splitLocation[5] === "chairmanform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/content/upload/question-source`, state: { data: "" } }}>Add Question's Source</NavLink>
                        </li>
                    </ul>
                    <div>
                        <NestedUploads />
                    </div>
                </div>
            </div>}
        </div>
    )
}

