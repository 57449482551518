import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_PARENT_BY_CHILDRENS, GET_USER_USER_CODE } from "../../../app/service/users.service";
import appConfig from "../../../config/constant";
import FATHERIMG from '../../../institute-dashboard-assets/images/father-icon.svg'
import MOTHERIMG from '../../../institute-dashboard-assets/images/mother-icon.svg'
import SUBJECTCOMPLETE from '../../../institute-dashboard-assets/images/subj-completed-logo.svg'
import MNSLOGO from '../../../institute-dashboard-assets/images/mns_logo.png'
import ABHYAASLOGO from '../../../institute-dashboard-assets/images/Abyas-logo.svg'
import PARIKSHALOGO from '../../../institute-dashboard-assets/images/Pariksha-logo.svg'
import ProfileImageUpload from "../../../components/image-crop/profile-img-upload";
import UserTrialTickerMessage from "../../../components/ticker-message/trial-ticker";

export const ParentInformationDashboard = () => {
    const studentsCode = localStorage.getItem("user_code");
    const [childernList, setChildernList] = useState<any>([]);
    const history = useHistory();
    const [studentList, setStudentList] = useState<any>([]);
    const [childrenLoading, setChildrenLoading] = useState(false);
    const [parentInfo, setParentInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [modulesList, setModuleeList] = useState<any>([]);
    const [freeTrail, setFreeTrail] = useState<boolean>(false);
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState<boolean>(false);
    const splitLocation: any = window.location.href.split("?");
    const queryParamString = splitLocation[1];
    const [childIndexValue, setChildIndexValue] = useState<number | null>(null);
    const location = useLocation();


    const getQueryParam = (param: string) => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    };

    useEffect(() => {
        fecthAllData();
    }, []);
    useEffect(() => {
        const child = getQueryParam('child');
        if (child) {
            setChildIndexValue(parseInt(child));
            fecthAllData();
        }
    }, [location.search]);

    const fecthAllData = async () => {
        setLoading(true);
        try {
            await getParentDetails();
            const childranData: any = await getChildrens();
            await getModulesByUserCode(childranData);
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }
    }
    const getParentDetails = async () => {
        try {
            const response: any = await GET_USER_USER_CODE(studentsCode)
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response?.rows[0];
                setParentInfo(data);
            }
        } catch (error) {

        }
    }

    const getChildrens = async () => {
        try {
            const res: any = await GET_PARENT_BY_CHILDRENS(studentsCode, 0);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.student_code = studentsCode;
                        if (element.type === 'individual') {
                            element.name_of_school = element?.indv_school_details?.school_name;
                        }
                    });
                    setStudentList(data);
                    if (data.length > 0) {
                        const match = queryParamString?.match(/child=([^&]*)/);
                        if (match) {
                            const child: any = getQueryParam('child');
                            const childCount = parseInt(child);
                            setChildernList(data[childCount]);
                            openTabsData(data[childCount], childCount);
                            return data[childCount];
                        } else {
                            getFreeTrialStauts(data[0]);
                            setChildernList(data[0]);
                        }
                    }
                    return data[0];
                }
            }
        } catch (error) {

        }
    }
    const getModulesByUserCode = async (userData: any) => {
        try {
            const res: any = await GET_MODULES_BY_USER_CODES(userData?.user_code);
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                } else {
                    let data = res;
                    if (Array.isArray(data)) {
                        let prepared = data?.filter((el: any) => el?.module_code !== 'CAS-PARENT');
                        setModuleeList(prepared);
                    }
                    setChildrenLoading(false);
                    setChildrenLoading(false);
                }
            }
        } catch (error) {

        }
    };

    const openTabsData = (data: any, i: number) => {
        setChildernList(data);
        setChildrenLoading(true);
        setModuleeList([]);
        getModulesByUserCode(data);
        setFreeTrail(false);
        getFreeTrialStauts(data);
    }

    const openTest = (data: any) => {
        localStorage.setItem("moduleUuid", data.uuid);
        if (data.module_code === "CAS") {
            if (parentInfo?.gender === "female") {
                if (data.parent_details.mother?.module_status === "completed") {
                    history.push(`/parent-dashboard/view/servy-success/${data.uuid}/${studentsCode}/${data.user_details.user_code}`)
                }
                else {
                    history.push(`/parent-dashboard/view/servey-instructions/${data.user_details.user_code}/${data.module_code}`)
                }
            }
            if (parentInfo?.gender === "male") {

                if (data.parent_details.father?.module_status === "completed") {
                    history.push(`/parent-dashboard/view/servy-success/${data.uuid}/${studentsCode}/${data.user_details.user_code}`)
                }
                else {
                    history.push(`/parent-dashboard/view/servey-instructions/${data.user_details.user_code}/${data.module_code}`)
                }
            }
        }

    }


    function onClickViewReports(item: any) {
        if (item.module_code === "MATHS") {
            history.push(`/parent-dashboard/view/maths/reports/student/${item.user_details.user_code}`);
        } else if (item.module_code === "ABHYAAS") {
            history.push(`/parent-dashboard/view/abhyaas/initiative/${item.uuid}/${item?.user_details?.user_code}/${childernList?.institute_school_code}`);
        } else if (item.module_code === "PARIKSHA") {
            history.push(`/parent-dashboard/view/pariksha/initiative/${item.uuid}/${item?.user_details?.user_code}/${childernList?.institute_school_code}`);

        }
    }


    const getSubscriptionStatus = (status: string) => {
        switch (status) {
            case "SUBSCRIBED":
                return <li className="breadcrumb-item text-subc">Subscribed</li>;
            case "NOT SUBSCRIBED":
                return <li className="breadcrumb-item text-notsubc">Not Yet Subscribed</li>;
            case "SUBSCRIPTION EXPIRED":
                return <li className="breadcrumb-item text-notsubc">Subscription Expired</li>;
            case "DORMANT":
                return <li className="breadcrumb-item text-notsubc">Dormant</li>;
            default:
                return <li className="breadcrumb-item text-notsubc">Not Yet Subscribed</li>;
        }
    }
    const getFreeTrialStauts = (data: any) => {
        if (data?.account_type === "TRIAL" && data?.trial_start_dt && data?.trial_end_dt) {
            const trialStartDate = new Date(data?.trial_start_dt);
            const trialEndDate = new Date(data?.trial_end_dt);
            const currentDate = new Date();
            const endEqualsCurrent = currentDate?.toDateString() === trialEndDate?.toDateString();
            if (currentDate >= trialStartDate && (endEqualsCurrent || currentDate <= trialEndDate)) {
                setFreeTrail(true);
            }
            else if (data?.subscription_status !== "SUBSCRIBED") {
                handlePayNow();
            }
        } else if (data?.subscription_status !== "SUBSCRIBED") {
            handlePayNow();
        }
        else {
            setFreeTrail(false);
        }
    }
    const getModuelFreeTrial = (moduleCode: string, userInfo: any) => {
        const modulesFreeTrial: string = userInfo?.modules;
        if (moduleCode && modulesFreeTrial && freeTrail) {
            const modulesArray = modulesFreeTrial?.split(',').map((item: any) => item?.trim());
            return modulesArray?.includes(moduleCode);
        } else {
            return false;
        }
    }
    const handlePayNow = () => {
        setCanShowPaymentPopup(true);
    }
    const goForPayment = () => {
        history.push(`/parent-dashboard/view/${childernList?.user_code}/payments`)
    }
    const cancelPayment = () => {
        setCanShowPaymentPopup(false);

    }

    const getMemberShipStatus = () => {
        if (childernList?.subscription_status === "SUBSCRIBED" || childernList?.subscription_status === "DORMANT" || childernList?.subscription_status === "SUBSCRIPTION EXPIRED") {
            return true;
        } else {
            return false;
        }
    }
    return (

        <div>
            <div
                className={`modal fade ${canShowPaymentPopup ? "show" : "false"}`}
                style={{ display: canShowPaymentPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="payment_modal-dialog_popup " role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  payment_user_popup_info position-relative">
                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={cancelPayment} style={{ right: "20px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='payment_user_popup_block '>
                                <div className='mt-1 d-flex justify-content-center'>
                                    <div className="text-start ">
                                        {getMemberShipStatus() ?
                                            <div>
                                                <h5 className='f20 payment_mem_text'>To continue to access all the  <br />  initiatives on "I am Samarth"<br />  app, please renew your annual <br />membership.</h5>
                                            </div>
                                            :
                                            <div>
                                                <h5 className='f20 payment_mem_text'>To  access all the  initiatives on <br /> "I am Samarth" app, you need<br />to register for our annual<br /> membership.  </h5>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn payment_user_btn f18 px-4 px-md-5 payment_mem_text py-2' onClick={goForPayment}>{getMemberShipStatus() ? "Renew Membership" : "Get Membership"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 pt-0 px-0 border-bottom">
                            <li className="breadcrumb-item"><a>Dashboard</a></li>
                        </ol>
                    </nav>
                    <div className="border-bottom pb-3">
                        <div className="d-md-flex sc-profile-details">
                            <div className="mb-3 mb-md-0">
                                <ProfileImageUpload
                                    profileImg={parentInfo?.profile_img}
                                    currentUserData={parentInfo}
                                />
                            </div>
                            <div className="sc-profile-text ps-md-4">
                                <h2 className="mt-0 mb-2">{parentInfo?.name && parentInfo?.name?.charAt(0).toUpperCase() + parentInfo?.name?.slice(1)}</h2>
                                <p className="m-0 sc-profile-contact">
                                    <span>{parentInfo?.mobile_number}</span>{parentInfo?.mobile_number && parentInfo?.email_id && <span className="px-2">|</span>}<span>{parentInfo?.email_id}</span>
                                </p>
                                <p className="m-0 sc-profile-addrs">
                                    <span>{parentInfo?.state}</span> {parentInfo?.state && parentInfo?.district && <span>|</span>} <span>{parentInfo?.district}</span> {parentInfo?.district && parentInfo?.city_village && <span className="pe-1">|</span>}
                                    <span>{parentInfo?.city_village}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <h3 className="mt-4 mb-3 section-hd">Children Details</h3>
                    <ul className="nav nav-tabsnewui  custom-tabsnewui mt-4" id="myTab" role="tablist">
                        {studentList?.map((item: any, i: number) => {
                            return (
                                <li className="nav-item mb-2" role="presentation" key={i} >
                                    <button
                                        className={`nav-link ${(childIndexValue === i || (i === 0 && childIndexValue === null)) && 'active'} pr`}
                                        id="child1-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#child1"
                                        type="button"
                                        role="tab"
                                        aria-controls="child1"
                                        aria-selected="true"
                                        onClick={() => openTabsData(item, i)}>
                                        {i + 1}.

                                        {item?.name?.charAt(0).toUpperCase() + item?.name?.slice(1)}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="tab-content pt-3" id="myTabContent">
                        {childrenLoading &&
                            <div className="text-center p-5 mt-5">
                                <div className="spinner-border mt-5" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!childrenLoading &&
                            <div className="tab-pane fade show active" id="child1">
                                <div className="row pb-1">
                                    <div className="col-md-6">
                                        <div className="d-flex sc-profile-details small mb-4">
                                            <div className="sc-profile-img pt-0">
                                                {childernList?.gender === "female" ? <img
                                                    src={childernList?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${childernList?.profile_img.charAt(0) === "/" ? childernList?.profile_img.substring(1) : childernList?.profile_img}` : MOTHERIMG}
                                                    alt=""
                                                    className="pr-image"
                                                /> : <img
                                                    src={childernList?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${childernList?.profile_img.charAt(0) === "/" ? childernList?.profile_img.substring(1) : childernList?.profile_img}` : FATHERIMG}
                                                    alt=""
                                                    className="pr-image"
                                                />}
                                            </div>
                                            <div className="sc-profile-text ps-4">
                                                <ol className="breadcrumb border-0 mb-2 p-0">{getSubscriptionStatus(childernList?.subscription_status)} <span className="subscribe_btn_user f16 ms-2 cursor-pointer" onClick={handlePayNow}>{getMemberShipStatus() ? "Renew Now" : "Get Membership"}</span></ol>
                                                {childernList?.date_of_birth && <p className="sc-profile-deg mb-1">
                                                    Student | <span className="text-dgray">DOB  -  {childernList?.date_of_birth.slice(0, 11).replace(/-/g, "/")}</span>
                                                </p>}
                                                <h2 className="m-0">
                                                    {childernList?.name?.charAt(0).toUpperCase() + childernList?.name?.slice(1)} | <span>{childernList?.standard}th Class</span>
                                                </h2>
                                                <p className="m-0 sc-profile-contact">
                                                    <span>{childernList?.mobile_number}</span> |
                                                    <span className="ps-1">{childernList?.email_id}</span>
                                                </p>
                                                <p className="m-0 sc-profile-addrs">
                                                    <span>{childernList?.state}</span>{childernList?.district && <span>|</span>} <span>{childernList?.district}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {childernList?.account_type === "TRIAL" && <UserTrialTickerMessage userInfo={childernList} />}
                                {childernList?.subscription_status === "SUBSCRIBED" && <h3 className="mb-3 section-hd">Modules Opted</h3>}
                                {childernList?.subscription_status !== "SUBSCRIBED" && <h3 className="mb-3 section-hd">Modules Available for Purchase</h3>}
                                <div className="row">
                                    <div className="module_list_cards d-flex justify-content-center justify-content-md-start">
                                        {modulesList?.map((data: any, i: number) => {
                                            return (
                                                <div key={i} className={`module_card_view psurvey  position-relative ${(data?.module_code === "MATHS" || data?.module_code === "PARIKSHA") && "cardTwo"} ${data?.module_code === "ABHYAAS" && "cardThree"}`}>
                                                    <span className="text-dgray">
                                                        {/* <h2 className={`module-title  ${data.module_code !== "CAS" ? "mb-4" : "mb-0"}`}>{data.module_code}</h2> */}
                                                        {data.module_code === "MATHS" && <img className=" module_logo_img" src={MNSLOGO} alt="" />}
                                                        {data.module_code === "ABHYAAS" && <img className="  module_logo_img" src={ABHYAASLOGO} alt="" />}
                                                        {data.module_code === "PARIKSHA" && <img className=" module_logo_img" src={PARIKSHALOGO} alt="" />}
                                                        <div>
                                                            {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                                            {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyaas</h2>}
                                                            {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                                            {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                                            {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                                            {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                                            {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                                        </div>
                                                        <p className="mb-2 ">
                                                            {/* Payment - <label >{(freeTrail && getModuelFreeTrial(data?.module_code)) ? <span className="free_trial_btn f14">Free Trial</span> : (data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess"> Pending </span>} {data.amount_status === "COMPLETED" && <span className="text-completed"> Paid</span>}</label> */}
                                                            Payment - <label > {childernList?.subscription_status === "SUBSCRIBED" ? <span className="text-completed"> Paid</span> : (freeTrail && getModuelFreeTrial(data?.module_code, childernList)) ? <span className="free_trial_btn f14">Free Trial</span> : (childernList?.subscription_status !== "SUBSCRIBED" || data.amount_status === null) && <span className="text-inprogess"> Pending </span>} </label>
                                                        </p>
                                                        {((childernList?.subscription_status === "SUBSCRIBED") || (freeTrail && getModuelFreeTrial(data?.module_code, childernList))) ? <p className="m-0">
                                                            Status - <label>{(data.module_status === "inprogress" || data.module_status === null) && <span className="text-inprogess">In Progress {data?.module_code === "CAS" && <span onClick={() => openTest(data)} className="btn btn-continue ms-3">
                                                                Continue test</span>}</span>}{data.module_status === "completed" && <span className="text-completed"> Completed</span>}</label>
                                                        </p> : <p className="m-0">
                                                            {/* <span className="btn btn-pay" onClick={() => onPayments()}>Pay Now</span> */}
                                                        </p>
                                                        }
                                                        {data?.module_status === "completed" && <img
                                                            src={SUBJECTCOMPLETE}
                                                            alt=""
                                                            className="module-completed-logo"
                                                        />}
                                                        {(data?.module_status === "completed" || (childernList?.subscription_status === "SUBSCRIBED") || (freeTrail && getModuelFreeTrial(data?.module_code, childernList))) && <p className="m-0 mt-2"> <span className="btn btn-continue mt-2 " id={`${data?.module_code}_VIEW`} onClick={() => onClickViewReports(data)}> View Report </span></p>}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>}

                    </div>
                </div>}

        </div>
    )
}