import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../../components/breadcum";
import { useHistory } from "react-router-dom";
import FormBuilder from "../../../../components/form-builder";
import { toast } from "react-toastify";
import { GET_BOARD_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST } from "../../../../app/service/abhyas-worksheet-filter.service";

export const KaryakartaInfo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<string | number>("");
    const [filterData, setFilterData] = useState<any>({});
    const [showData, setShowData] = useState<string>("");
    const history = useHistory();
    const items = [
        { value: 1, label: "Today" },
        { value: 2, label: "Yesterday" },
        { value: 3, label: "Last 7 days" },
        { value: 4, label: "Last 30 days" },
        { value: 5, label: "Last year" },
        { value: 6, label: "Custom" },

    ];

    useEffect(() => {
        getBoardData();
    }, []);
    const getBoardData = () => {
        GET_BOARD_FILTERS_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                }
            }
        });
    }

    const onChangeBoard = (e: any) => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        if (e) {
            GET_MEDIUM_FILTERS_LIST(e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        const data = res['rows'];
                        setMediumList(data);
                    }
                }
            });
        }
    }
    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        if (e) {
            GET_STANDARD_FILTERS_LIST(filterData?.board_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                    } else {
                        const data = res['rows'];
                        setStandardList(data);
                    }
                }
            });
        }
    }
    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        if (e) {
            GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                    } else {
                        const data = res['rows'];
                        setSubjectList(data);
                    }
                }
            });
        }
    }
    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);

    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (item: any) => {
        setSelectedItem(item.label);
        setIsOpen(false);
    };
    const handleBack = () => {
        history.push(`/dashboard/content/karyakarta/list`)
    }
    const handleView = (viewData: string) => {
        setShowData(viewData);
    }
    return (
        <div className=" border-top border-primary py-3">
            <div className="bg_gray_ligth">
                <p className="p-2 f16 text-primary cursor-pointer " onClick={handleBack}>Karyakarta Analysis</p>
            </div>
            <h5 className="mb-4 text-center">Karyakarta Name</h5>
            <div className="row justify-content-center">
                <div className="col-12 col-md-11">
                    <div className="karya_display_bx">
                        <div className="karya_bx text-center position-relative" onClick={() => handleView("question")}>
                            <h1 className="text_uline mb-0 gn_karya">12</h1>
                            <h6 className="mb-0 f16">Total Questions Created</h6>
                            {showData === "question" && <p className="ms-3 trapezoid">
                            </p>}
                        </div>
                        <div className="karya_bx text-center" onClick={() => handleView("test")}>
                            <h1 className="text_uline mb-0 test_karya">12</h1>
                            <h6 className="mb-0 f16">Total Tests Created</h6>
                            {showData === "test" && <p className="ms-3 trapezoid">
                            </p>}
                        </div>
                        <div className="karya_bx text-center" onClick={() => handleView("worksheets")}>
                            <h1 className="text_uline mb-0 test_karya">12</h1>
                            <h6 className="mb-0 f16">Total Worksheets Created</h6>
                            {showData === "worksheets" && <p className="ms-3 trapezoid">
                            </p>}
                        </div>
                        <div className="karya_bx text-center" onClick={() => handleView("hours")}>
                            <h1 className="text_uline mb-0 hours_karya">12</h1>
                            <h6 className="mb-0 f16">Total Hours on System</h6>
                            {showData === "hours" && <p className="ms-3 trapezoid">
                            </p>}
                        </div>
                    </div>

                </div>

            </div>
            {showData && <div className="filters_karya mx-3 p-3 mt-4">
                <FormBuilder onUpdate={handleInput}>
                    <form >
                        <div className="karya_filter_list">
                            <div className="d-block">
                                <div>
                                    <label className="form-label mb-0 f14">Date Range</label>
                                </div>
                                <div className="select-dropdown">
                                    <button
                                        role="button"
                                        className="select-dropdown__button"
                                        onClick={toggleDropdown}
                                    >
                                        <span>{selectedItem || "Custom"}</span>
                                        <i className="zmdi zmdi-chevron-down">&#129171;</i>
                                    </button>
                                    <ul
                                        className={`select-dropdown__list ${isOpen ? "active" : ""}`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {items.map((item) => (
                                            <li
                                                key={item.value}
                                                data-value={item.value}
                                                className="select-dropdown__list-item"
                                                onClick={() => handleSelect(item)}
                                            >
                                                {item.label}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="">
                                <label className="form-label mb-0 f14">From Date
                                </label>
                                <input type="date" className='form-control f14 boarder_org_cl' name="from_date" onKeyDown={(event) => {
                                    event.preventDefault();
                                }} />
                            </div>
                            <div className="">
                                <label className="form-label mb-0 f14">To Date
                                </label>
                                <input type="date" className='form-control f14 boarder_org_cl' name="to_date" onKeyDown={(event) => {
                                    event.preventDefault();
                                }} />
                            </div>
                            <div className='mb-4'>
                                <label className="f14">Board</label>
                                <select name="board_uuid" id="board_uuid" className="form-select f14 boarder_org_cl" onChange={(e) => { onChangeBoard(e.target.value) }}>
                                    <option value="">Select Board</option>
                                    {boardsList?.map((name: any, i: number) => (
                                        <option key={i} value={name?.uuid}>
                                            {name?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Medium</label>
                                <select name="medium_uuid" id="medium_uuid" className="form-select f14 boarder_org_cl" onChange={(e) => { onChangeMedium(e.target.value) }}>
                                    <option value="">Select Medium</option>
                                    {mediumList?.map((name: any, i: number) => (
                                        <option key={i} value={name?.uuid}>
                                            {name?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="f14">Standard</label>
                                <select name="standard_uuid" id="standard_uuid" className="form-select f14  boarder_org_cl" onChange={(e) => { onChangeStandard(e.target.value) }}>
                                    <option value="">Select Standard</option>
                                    {standardList?.map((name: any, i: number) => (
                                        <option key={i} value={name?.uuid}>
                                            {name?.standard}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="f14">Subject</label>
                                <select name="subject_uuid" id="subject_uuid" className="form-select f14  boarder_org_cl">
                                    <option value="">Select Subject</option>
                                    {subjectList?.map((name: any, i: number) => (
                                        <option key={i} value={name?.uuid}>
                                            {name?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="f14">Difficulty Level</label>
                                <select name="level" id="level" className="form-select f14 boarder_org_cl">
                                    <option value="">All</option>
                                    <option value="EASY">Easy</option>
                                    <option value="MEDIUM">Medium</option>
                                    <option value="HARD">Hard</option>

                                </select>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <p className="f14 mb-0 text-start"><span className="fw-bold">Duration:</span></p>
                    </div>
                    <div className="col-12 col-md-4">
                        <p className="f14 mb-0 text-end"><span className="fw-bold">Total Questions Uploaded:</span></p>
                    </div>
                </div>
                <div>

                </div>
            </div>}
        </div>
    )
}