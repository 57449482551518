import React from "react";
import { useHistory } from "react-router-dom";
import { ContentMediumList } from "../../../../components/mediums/list";

export const ContentMedium = () => {
    const history = useHistory();


    return (
        <div>
            <div className=" py-3">
                <ContentMediumList isLinked={""} display={""} boardUuid={undefined} navigateurl="/dashboard/content/upload" />
            </div>
        </div>
    )
}