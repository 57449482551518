import React from "react";
import { useHistory } from "react-router-dom";
import { ContentBoardList } from "../../../../components/boards/list";

export const ContentBoards = () => {
    const history = useHistory();


    return (
        <div>
            <div className=" py-3">
                <ContentBoardList isLinked={""} navigateurl="/dashboard/content/upload" />
            </div>
        </div>
    )
}