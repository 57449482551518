import React from 'react'
import { Route, Switch } from 'react-router'
import { UploadInfo } from '../../pages/dashboard/content/uploads/upload-info'
import { ContentAddQuestions } from '../../pages/dashboard/content/uploads/add-questions/list'
import { ContentAddQuestionSource } from '../../pages/dashboard/content/uploads/add-question-source/list'
import { ContentSearchQuestionCode } from '../../pages/dashboard/content/uploads/search-question-code/list'
import { ContentQuestionValidationStatus } from '../../pages/dashboard/content/uploads/validation-status/list'
import { ContentUploadVideos } from '../../pages/dashboard/content/uploads/add-videos/list'

const NestedUploads = () => {
    return (
        <Switch>
            <Route path="/dashboard/content/upload/add-question" component={ContentAddQuestions}></Route>
            <Route path="/dashboard/content/upload/question-source" component={ContentAddQuestionSource}></Route>
            <Route path="/dashboard/content/upload/question-code" component={ContentSearchQuestionCode}></Route>
            <Route path="/dashboard/content/upload/question-status" component={ContentQuestionValidationStatus}></Route>
            <Route path="/dashboard/content/upload/add-videos" component={ContentUploadVideos}></Route>
            <Route path="/dashboard/content/upload" component={UploadInfo}></Route>
        </Switch>
    )
}

export default NestedUploads